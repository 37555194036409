import {loader} from "../parts/loader"

export const checkout = () => {

    let submitted = false
    let lastNipVal = ''

    function zipMasks()
    {

        const inputs = $('[data-mask=postalCode]')

        inputs.each( function () {

            $(this).inputmask("99-999")

        })

    }

    function toggleInvoice()
    {

        const checkbox = $('input[data-field=invoicebox]')
        const invoiceBox = $('#invoiceBox')

        if (checkbox.is(':checked'))
        {
            invoiceBox.slideDown('fast')
        }
        else
        {
            invoiceBox.slideUp('fast')
        }

    }

    $(document).on('click', '#modalDeliveryToggle', function (e) {

        const firstName = $('[data-delivery=firstName]')
        const lastName = $('[data-delivery=lastName]')
        const company = $('[data-delivery=companyName]')
        const address = $('[data-delivery=address]')
        const zip = $('[data-delivery=zipCode]')
        const city = $('[data-delivery=city]')
        const phone = $('[data-delivery=phone]')
        const email = $('[data-delivery=email]')

        const formFirstName = $('#shipping_first_name')
        const formLastName = $('#shipping_last_name')
        const formCompanyName = $('#shipping_company_name')
        const formAddress = $('#shipping_address')
        const formZip = $('#shipping_zip_code')
        const formCity = $('#shipping_city')
        const formPhone = $('#shipping_phone')
        const formEmail = $('#shipping_email')

        formFirstName.val(firstName.html())
        formLastName.val(lastName.html())
        formCompanyName.val(company ? company.html() : '')
        formAddress.val(address.html())
        formZip.val(zip.html())
        formCity.val(city.html())
        formPhone.val(phone.html())
        formEmail.val(email.html())

        $('#editErrors').hide()

    })

    $(document).on('click', '.editAddressSave', function () {
        const firstName = $('[data-delivery=firstName]')
        const lastName = $('[data-delivery=lastName]')
        const company = $('[data-delivery=companyName]')
        const address = $('[data-delivery=address]')
        const zip = $('[data-delivery=zipCode]')
        const city = $('[data-delivery=city]')
        const phone = $('[data-delivery=phone]')
        const email = $('[data-delivery=email]')

        const formFirstName = $('#shipping_first_name')
        const formLastName = $('#shipping_last_name')
        const formCompanyName = $('#shipping_company_name')
        const formAddress = $('#shipping_address')
        const formZip = $('#shipping_zip_code')
        const formCity = $('#shipping_city')
        const formPhone = $('#shipping_phone')
        const formEmail = $('#shipping_email')

        let errors = false;

        if (formFirstName.val().trim() !== '')
        {
            firstName.html(formFirstName.val().trim())
            $('[data-field=deliveryName]').val(formFirstName.val().trim())
        }
        else
        {
            formFirstName.addClass('error')
            errors = true;
        }

        if (formLastName.val().trim() !== '')
        {
            lastName.html(formLastName.val().trim())
            $('[data-field=deliverySurname]').val(formLastName.val().trim())
        }
        else
        {
            formLastName.addClass('error')
            errors = true;
        }

        if (formCompanyName.val().trim() !== '')
        {
            company.html(formCompanyName.val().trim()).show()
            $('[data-field=deliveryCompanyName]').val(formCompanyName.val().trim())
            $('[data-delivery=companyNameBr]').show()
        }
        else
        {
            company.hide()
            $('[data-delivery=companyNameBr]').hide()
        }

        if (formAddress.val().trim() !== '')
        {
            address.html(formAddress.val().trim())
            $('[data-field=deliveryAddress]').val(formAddress.val().trim())
        }
        else
        {
            formAddress.addClass('error')
            errors = true;
        }

        if (formZip.val().trim() !== '')
        {
            zip.html(formZip.val().trim())
            $('[data-field=deliveryZipCode]').val(formZip.val().trim())
        }
        else
        {
            formZip.addClass('error')
            errors = true;
        }

        if (formCity.val().trim() !== '')
        {
            city.html(formCity.val().trim())
            $('[data-field=deliveryCity]').val(formCity.val().trim())
        }
        else
        {
            formCity.addClass('error')
            errors = true;
        }

        if (formPhone.val().trim() !== '')
        {
            phone.html(formPhone.val().trim())
            $('[data-field=deliveryPhoneNumber]').val(formPhone.val().trim())
        }
        else
        {
            formPhone.addClass('error')
            errors = true;
        }

        if (formEmail.val().trim() !== '')
        {
            email.html(formEmail.val().trim())
            $('[data-field=deliveryEmail]').val(formEmail.val().trim())
        }
        else
        {
            formEmail.addClass('error')
            errors = true;
        }

        if (errors)
        {
            $('#editErrors').show()
        }
        else
        {
            $('#modalDelivery').modal('hide')
        }

    })

    $(document).on('click', '#modalInvoiceToggle', function (e) {

        const company = $('[data-invoice=companyName]')
        const address = $('[data-invoice=address]')
        const zip = $('[data-invoice=zipCode]')
        const city = $('[data-invoice=city]')
        const nip = $('[data-invoice=nip]')

        const formCompanyName = $('#invoice_company')
        const formAddress = $('#invoice_address')
        const formZip = $('#invoice_zip_code')
        const formCity = $('#invoice_city')
        const formNip = $('#invoice_nip')

        formCompanyName.val(company ? company.html() : '')
        formAddress.val(address.html())
        formZip.val(zip.html())
        formCity.val(city.html())
        formNip.val(nip.html())

        $('#editInvoiceErrors').hide()
    })

    $(document).on('change', '#invoice_private', function () {

        const checkbox = $(this);
        let nip = $('#invoice_nip')

        if (checkbox.is(':checked'))
        {

            lastNipVal = nip.val();
            nip.val('-').attr('disabled', true)
        }
        else
        {
            nip.val(lastNipVal).attr('disabled', false)
        }

    })

    $(document).on('click', '.editInvoiceSave', function (e) {

        const company = $('[data-invoice=companyName]')
        const address = $('[data-invoice=address]')
        const zip = $('[data-invoice=zipCode]')
        const city = $('[data-invoice=city]')
        const nip = $('[data-invoice=nip]')

        const formCompanyName = $('#invoice_company')
        const formAddress = $('#invoice_address')
        const formZip = $('#invoice_zip_code')
        const formCity = $('#invoice_city')
        const formNip = $('#invoice_nip')

        let errors = false;

        if (formCompanyName.val().trim() !== '')
        {
            company.html(formCompanyName.val().trim())
            $('[data-field=invoiceCompanyName]').val(formCompanyName.val().trim())
        }
        else
        {
            formCompanyName.addClass('error')
            errors = true;
        }

        if (formAddress.val().trim() !== '')
        {
            address.html(formAddress.val().trim())
            $('[data-field=invoiceAddress]').val(formAddress.val().trim())
        }
        else
        {
            formAddress.addClass('error')
            errors = true;
        }

        if (formZip.val().trim() !== '')
        {
            zip.html(formZip.val().trim())
            $('[data-field=invoiceZipCode]').val(formZip.val().trim())
        }
        else
        {
            formZip.addClass('error')
            errors = true;
        }

        if (formCity.val().trim() !== '')
        {
            city.html(formCity.val().trim())
            $('[data-field=invoiceCity]').val(formCity.val().trim())
        }
        else
        {
            formCity.addClass('error')
            errors = true;
        }

        if ($('#invoice_private').is(':checked'))
        {
            $('[data-field=invoiceNip]').val('')
            nip.html('-')
        }
        else
        {
            if (formNip.val().trim() !== '')
            {
                nip.html(formNip.val().trim())
                $('[data-field=invoiceNip]').val(formNip.val().trim())
            }
            else
            {
                formNip.addClass('error')
                errors = true;
            }
        }

        if (errors)
        {
            $('#editInvoiceErrors').show()
        }
        else
        {
            $('#modalInvoice').modal('hide')
        }

    })

    $(document).on('click', '.newAddr', function () {
        const button = $(this)
        const mode = button.data('mode')
        const target = button.data('target')
        const url = Routing.generate('checkout_add_address', {type: mode})

        if (!$(target).hasClass('show'))
        {
            $(target).html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

            $.ajax({
                type: 'get',
                url: url
            }).done((d) => {
                if (d.form)
                {
                    $(target).html(d.form)
                    zipMasks()
                }
            })
        }

    })

    $(document).on('submit', '#addressForm', function (e) {
        e.preventDefault();

        const parent = $(this).parents('.collapse')
        const mode = parent.data('mode')
        const modalId = parent.data('modal')
        const url = Routing.generate('checkout_add_address', {type: mode})

        parent.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'post',
            url: url,
            data: $(this).serialize()
        }).done(d => {
            if (d.html)
            {
                $(modalId).modal('hide')
                $('#modal-holder').html(d.html)
                $(modalId).modal('show')
            }
            else if (d.form)
            {
                parent.html(d.form)
                zipMasks()
            }
        })

    })

    $(document).on('click', '.setAddressSave', function () {

        const firstName = $('[data-delivery=firstName]')
        const lastName = $('[data-delivery=lastName]')
        const company = $('[data-delivery=companyName]')
        const address = $('[data-delivery=address]')
        const zip = $('[data-delivery=zipCode]')
        const city = $('[data-delivery=city]')
        const phone = $('[data-delivery=phone]')
        const email = $('[data-delivery=email]')

        const formFirstName = $('[data-field=deliveryName]')
        const formLastName = $('[data-field=deliverySurname]')
        const formCompanyName = $('[data-field=deliveryCompanyName]')
        const formAddress = $('[data-field=deliveryAddress]')
        const formZip = $('[data-field=deliveryZipCode]')
        const formCity = $('[data-field=deliveryCity]')
        const formPhone = $('[data-field=deliveryPhoneNumber]')
        const formEmail = $('[data-field=deliveryEmail]')

        const radios = $('[name=wysylka]');

        let checked = null

        radios.each(function () {

            if ($(this).is(':checked'))
            {
                checked = $(this)
            }

        })

        if (checked)
        {

            const addressHolder = checked.siblings('.delivery-wrapper').eq(0).children('p').eq(0)


            let elemFirstName = $(addressHolder).find('[data-address=firstName]').html()
            let elemLastName = $(addressHolder).find('[data-address=lastName]').html()
            let elemCompanyName = $(addressHolder).find('[data-address=company]').html()
            let elemAddress = $(addressHolder).find('[data-address=address]').html()
            let elemZip = $(addressHolder).find('[data-address=zip]').html()
            let elemCity = $(addressHolder).find('[data-address=city]').html()
            let elemPhone = $(addressHolder).find('[data-address=phone]').html()
            let elemEmail = $(addressHolder).find('[data-address=email]').html()

            firstName.html(elemFirstName)
            formFirstName.val(elemFirstName)

            lastName.html(elemLastName)
            formLastName.val(elemLastName)

            if (elemCompanyName)
            {
                company.html(elemCompanyName).show()
                formCompanyName.val(elemCompanyName)
                $('[data-delivery=companyNameBr]').show()
            }
            else
            {
                company.hide()
                $('[data-delivery=companyNameBr]').hide()
            }

            address.html(elemAddress)
            formAddress.val(elemAddress)

            zip.html(elemZip)
            formZip.val(elemZip)

            city.html(elemCity)
            formCity.val(elemCity)

            phone.html(elemPhone)
            formPhone.val(elemPhone)

            email.html(elemEmail)
            formEmail.val(elemEmail)

        }


        $('#modalDelivery').modal('hide')
        $('.modal-backdrop').remove()

    })

    $(document).on('click', '.setInvoiceSave', function () {

        const company = $('[data-invoice=companyName]')
        const address = $('[data-invoice=address]')
        const zip = $('[data-invoice=zipCode]')
        const city = $('[data-invoice=city]')
        const nip = $('[data-invoice=nip]')

        const formCompanyName = $('[data-field=invoiceCompanyName]')
        const formAddress = $('[data-field=invoiceAddress]')
        const formZip = $('[data-field=invoiceZipCode]')
        const formCity = $('[data-field=invoiceCity]')
        const formNip = $('[data-field=invoiceNip]')

        const radios = $('[name=faktura]');

        let checked = null

        radios.each(function () {

            if ($(this).is(':checked'))
            {
                checked = $(this)
            }

        })

        if (checked)
        {

            const addressHolder = checked.siblings('.delivery-wrapper').eq(0).children('p').eq(0)

            let elemCompanyName = $(addressHolder).find('[data-address=company]').html()
            let elemAddress = $(addressHolder).find('[data-address=address]').html()
            let elemZip = $(addressHolder).find('[data-address=zip]').html()
            let elemCity = $(addressHolder).find('[data-address=city]').html()
            let elemNip = $(addressHolder).find('[data-address=nip]').html()


            company.html(elemCompanyName).show()
            formCompanyName.val(elemCompanyName)

            address.html(elemAddress)
            formAddress.val(elemAddress)

            zip.html(elemZip)
            formZip.val(elemZip)

            city.html(elemCity)
            formCity.val(elemCity)

            nip.html(elemNip)
            formNip.val(elemNip)

        }


        $('#modalInvoice').modal('hide')
        $('.modal-backdrop').remove()

    })

    $(document).on('submit', 'form[name=order]', function (e) {

        if (!submitted)
        {
            e.preventDefault()
            loader.show()
            submitted = true
            $(this).submit()
        }
    })

    $(document).on('change', 'input[data-field=invoicebox]', () => {
        toggleInvoice()
    })

    $(() => {
        zipMasks()
        toggleInvoice()
    })

}