import {loader} from "../parts/loader";

export const post = id => {

    const formHolder = $('#comment-form')
    const alertHolder = $('#comment-alert')

    const getForm = () => {

        loader.show()

        $.ajax({
            type: 'get',
            url: Routing.generate('post_comment', {id: id})
        }).done( d => {

            formHolder.html(d.html)
            loader.hide()

        })

    }

    /**
     * @param {boolean} type
     */
    const toggleLike = (type) => {
        const likesCount = $('#likesNo'),
            btnHolder = $('#like-button')
        let url, button

        if (type)
        {
            url = Routing.generate('post_like', {id: id})
            button = `<a href="javascript:void(0)" class="toggle-like" data-type="0">
                                                <i class="fas fa-thumbs-up"></i> Lubisz to
                                            </a>`
        }
        else
        {
            url = Routing.generate('post_dislike', {id: id})
            button = ` <a href="javascript:void(0)" class="toggle-like" data-type="1">
                                                <i class="far fa-thumbs-up"></i> Polub post
                                            </a>`
        }


        loader.show()

        $.ajax({
            type: 'patch',
            url: url
        }).done(d => {
            let icon, title, text
            if (d.error) {
                icon = 'error'
                title = 'Błąd'
                text = d.error
            } else {
                icon = 'success'
                title = 'Sukces'
                if (d.ok === 'added') {
                    text = 'Polubiono wpis'
                    likesCount.html(Number(likesCount.html()) + 1)
                }
                else {
                    text = 'Usunięto polubienie wpisu'
                    likesCount.html(Number(likesCount.html()) - 1)
                }
                btnHolder.html(button)
            }

            loader.hide()

            Swal.fire({
                icon: icon,
                title: title,
                text: text
            }).then()
        })
    }

    $(document).on('submit', '*[data-send="ajax"]', function (e) {

        e.preventDefault()

        let form = $(this)

        formHolder.hide()
        alertHolder.hide()
        loader.show()

        $.ajax({
            type: 'post',
            url: Routing.generate('post_comment', {id: id}),
            data: form.serialize()
        }).done(d => {
            if (d.error)
            {
                alertHolder.html(`<div class="alert alert-danger" role="alert">
                              Wystąpił błąd serwera
                            </div>`)
            }
            else if (d.ok)
            {
                formHolder.find('textarea').val('')
                alertHolder.html(`<div class="alert alert-success" role="alert">
                              Komentarz został dodany. Poczekaj na zatwierdzenie przez Administrację.
                            </div>`)
            }

            alertHolder.show()
            formHolder.show()
            loader.hide()
        })

    })

    $(document).on('click', '.participate', function () {

        const type = $(this).data('type')

        loader.show()

        const url = Routing.generate(type === 0 ? 'project_resign' : 'project_participate', {id: id})

        $.ajax({
            type: 'get',
            url: url
        }).done( d =>{

            let icon, title, text, reload

            if (d.error)
            {
                icon = 'error'
                title = 'Błąd!'
                text = d.error
                reload = false
            }
            else
            {
                icon = 'success'
                title = 'Sukces!'
                text = d.ok
                reload = true
            }

            Swal.fire({
                icon: icon,
                title: title,
                text: text,
                allowOutsideClick: !reload
            }).then( () => {

                if (reload)
                    location.reload()

            })

            loader.hide()

        }).fail( f => {

            loader.hide()

            Swal.fire({
                icon: 'error',
                title: 'Błąd!',
                text: 'Wystąpił błąd serwera'
            })

        })

    })

    $(document).on('click', '.toggle-like', function (e) {
        e.preventDefault()
        toggleLike(Boolean($(this).data('type')))
    })

    $(() => {
        getForm()
    })

}