window.initSlick = () => {
    /*===================================================================================*/
    /*  Slick Carousel
    /*===================================================================================*/

    $('[data-ride="tm-slick-carousel"]').each( function() {
        var $slick_target = false;

        if ( $(this).data( 'slick' ) !== 'undefined' && $(this).find( $(this).data( 'wrap' ) ).length > 0 ) {
            $slick_target = $(this).find( $(this).data( 'wrap' ) );
            $slick_target.data( 'slick', $(this).data( 'slick' ) );
        } else if ( $(this).data( 'slick' ) !== 'undefined' && $(this).is( $(this).data( 'wrap' ) ) ) {
            $slick_target = $(this);
        }

        if( $slick_target ) {
            $slick_target.slick();
        }
    });

    $(".custom-slick-pagination .slider-prev").click(function(e){
        if ( $( this ).data( 'target' ) !== 'undefined' ) {
            e.preventDefault();
            e.stopPropagation();
            var slick_wrap_id = $( this ).data( 'target' );
            $( slick_wrap_id ).slick('slickPrev');
        }
    });

    $(".custom-slick-pagination .slider-next").click(function(e){
        if ( $( this ).data( 'target' ) !== 'undefined' ) {
            e.preventDefault();
            e.stopPropagation();
            var slick_wrap_id = $( this ).data( 'target' );
            $( slick_wrap_id ).slick('slickNext');
        }
    });

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var $target = $(e.target).attr("href");
        $($target).find('[data-ride="tm-slick-carousel"]').each( function() {
            var $slick_target = $(this).data('wrap');
            if( $($target).find($slick_target).length > 0 ) {
                $($target).find($slick_target).slick('setPosition');
            }
        });
    });

    $('#section-landscape-product-card-with-gallery .products').on('init', function(event, slick){
        $(slick.$slides[0]).find(".big-image figure:eq(0)").nextAll().hide();
        $(slick.$slides[0]).find(".small-images figure").click(function(e){
            var index = $(this).index();
            $(slick.$slides[0]).find(".big-image figure").eq(index).show().siblings().hide();
        });
    });

    $("#section-landscape-product-card-with-gallery .products").slick({
        'infinite'			: false,
        'slidesToShow'		: 1,
        'slidesToScroll'	: 1,
        'dots'				: false,
        'arrows'			: true,
        'prevArrow'			: '<a href="#"><i class="tm tm-arrow-left"></i></a>',
        'nextArrow'			: '<a href="#"><i class="tm tm-arrow-right"></i></a>'
    });

    $("#section-landscape-product-card-with-gallery .products").slick('setPosition');

    $('#section-landscape-product-card-with-gallery .products').on('afterChange', function(event, slick, currentSlide){
        var current_element = $(slick.$slides[currentSlide]);
        current_element.find(".big-image figure:eq(0)").nextAll().hide();
        current_element.find(".small-images figure").click(function(e){
            var index = $(this).index();
            current_element.find(".big-image figure").eq(index).show().siblings().hide();
        });
    });
}