import 'inputmask/dist/jquery.inputmask'
import {loader} from "../parts/loader"

export const loginRegister = () => {

    const checkbox = $('[data-invoice]')

    let emailInputShow = false

    function invoiceRequired()
    {

        const inputs = $('[data-required=invoice]')

        inputs.each(function () {
            $(this).attr('required', checkbox.is(':checked'))
        })

    }

    function zipMasks()
    {

        const inputs = $('[data-mask=postalCode]')

        inputs.each( function () {

            $(this).inputmask("99-999")

        })

    }

    $(document).on('change', '.register-email', () => {
        if (!emailInputShow)
        {
            $('#register-billing').css("display", "flex").hide().fadeIn()
            emailInputShow = true
        }
    })

    $(document).on('change', checkbox, () => {
        invoiceRequired()
    })

    const passwordHolder = $('.password')
    const length = $('#length')
    const letters = $('#letter')
    const digits = $('#digit')
    const special = $('#special')
    const button = $('.registerButton')

    function validatePassword(password)
    {
        const r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,4096}$/

        return r.test(password)
    }

    function validateLength(password)
    {

        return password.length >= 8

    }

    function validateLetters(password)
    {
        const r = /(?=.*[a-z])(?=.*[A-Z])/

        return r.test(password)
    }

    function validateDigits(password)
    {
        const r = /(?=.*\d.*)/

        return r.test(password)
    }

    function validateSpecial(password)
    {
        const r = /(?=.*\W.*)/

        return r.test(password)
    }

    function validateAll()
    {

        try
        {
            if (validateLength(passwordHolder.val()))
            {
                length.addClass('text-success')
                length.removeClass('text-danger')
            }
            else
            {
                length.removeClass('text-success')
                length.addClass('text-danger')
            }

            if (validateLetters(passwordHolder.val()))
            {
                letters.addClass('text-success')
                letters.removeClass('text-danger')
            }
            else
            {
                letters.removeClass('text-success')
                letters.addClass('text-danger')
            }

            if (validateDigits(passwordHolder.val()))
            {
                digits.addClass('text-success')
                digits.removeClass('text-danger')
            }
            else
            {
                digits.removeClass('text-success')
                digits.addClass('text-danger')
            }

            if (validateSpecial(passwordHolder.val()))
            {
                special.addClass('text-success')
                special.removeClass('text-danger')
            }
            else
            {
                special.removeClass('text-success')
                special.addClass('text-danger')
            }

            if (validatePassword(passwordHolder.val()))
            {

                button.attr('disabled', false)

            }
            else
            {

                button.attr('disabled', true)

            }
        }
        catch (e)
        {

        }

    }

    $(() => {
        const emailHolder = $('.register-email');

        if (emailHolder.val() !== '')
        {
            $('#register-billing').css("display", "flex").hide().fadeIn()
            emailInputShow = true
        }

        invoiceRequired()
        zipMasks()
        validateAll()
    })

    $(document).on('click', '#get-company-data', function () {
        loader.show()
        const nip = $('#registration_addresses_1_nip').val()
        $.ajax({
            url: Routing.generate('get_company_data_by_nip', {nip: nip})
        }).done( (d) => {
            loader.hide()
            if (d.ok)
            {
                $('#registration_addresses_1_companyName').val(d.data.name)
                $('#registration_addresses_1_address').val(d.data.street + ' ' + d.data.number)
                $('#registration_addresses_1_zipCode').val(d.data.zipCode)
                $('#registration_addresses_1_city').val(d.data.city)
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'Nie udało się pobrać danych z GUS',
                    text: d.error,
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5',
                })
            }
        })
    })

    $(document).on('keyup', '.password' , () => {

        validateAll()

    })

    $(document).on('change', '.register-email', function () {

        const addressEmail = $('.address-email')

        addressEmail.val($(this).val())

    })

}