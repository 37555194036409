import {searchWidget} from "../../parts/searchWidget";

const searchBar = $('#searchBar')
const categorySelect = $('#productCat')
const searchBtn = $('#searchBtn')
const mobileSearchForm = $('#mobileSearchForm')
const mobileSearchInput = $('#mobileSearch')
const mobileQuickSearch = $('#mobile-quick-search')

let typingTimer;
let lastSearch;

function getSimilar(force = false)
{

    const query = searchBar.val()

    if (query !== lastSearch || force)
    {
        lastSearch = query;

        if (categorySelect.val() !== '0')
            searchWidget.getSimilarQueries(query, categorySelect.val())
        else
            searchWidget.getSimilarQueries(query)
    }

}

function getSimilarMobile()
{

    const query = mobileSearchInput.val()

    if (query !== lastSearch)
    {
        lastSearch = query;

        searchWidget.getSimilarQueriesMobile(query)
    }

}

mobileSearchForm.on('submit', () => {
    if (mobileSearchInput.val() !== '')
        location.href = Routing.generate('search') + "?q=" + mobileSearchInput.val()
})

searchBtn.on('click', () => { // TODO: Co z pustym polem wyszukiwania?
    if (categorySelect.val() !== '0')
        location.href = Routing.generate('search') + "?q=" + searchBar.val() + "&c=" + categorySelect.val()
    else
        location.href = Routing.generate('search') + "?q=" + searchBar.val()
})

searchBar.on('keydown', (d) => {
    clearTimeout(typingTimer);
    if (d.keyCode === 13) {
        if (categorySelect.val() !== '0')
            location.href = Routing.generate('search') + "?q=" + searchBar.val() + "&c=" + categorySelect.val()
        else
            location.href = Routing.generate('search') + "?q=" + searchBar.val()
    }
})

$(document).on('click', function (e) {
    if (!$('#similar-queries-box').is(":visible")) return

    if ($(e.target).parents('#similar-queries-box').length === 0 && $(e.target).parents('.navbar-search').length === 0)
    {
        $('#similar-queries-box').slideUp('slow')
    }
})

searchBar.on('focus', () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(getSimilar, 300);
})

searchBar.on('keyup', () => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(getSimilar, 300);
})

categorySelect.on('change', () => {
    if (searchBar.val() !== '')
    {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => getSimilar(true), 300);
    }
})

mobileSearchInput.on('focusout', () => {
    mobileQuickSearch.slideUp('slow')
})

mobileSearchInput.on('focus', () => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(getSimilarMobile, 300);
})

mobileSearchInput.on('keyup', () => {
    clearTimeout(typingTimer)
    typingTimer = setTimeout(getSimilarMobile, 300);
})
