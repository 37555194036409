export const passwordValidation = () => {

    const passwordHolder = $('.password')
    const passwordRepeatHolder = $('.repeatPassword')
    const length = $('#length')
    const letters = $('#letter')
    const digits = $('#digit')
    const special = $('#special')
    const same = $('#same')
    const button = $('.resetButton')

    function validatePassword(password)
    {
        const r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{8,4096}$/

        return r.test(password)
    }

    function validateLength(password)
    {

        return password.length >= 8

    }

    function validateLetters(password)
    {
        const r = /(?=.*[a-z])(?=.*[A-Z])/

        return r.test(password)
    }

    function validateDigits(password)
    {
        const r = /(?=.*\d.*)/

        return r.test(password)
    }

    function validateSpecial(password)
    {
        const r = /(?=.*\W.*)/

        return r.test(password)
    }

    function validateAll()
    {

        try
        {
            if (validateLength(passwordHolder.val()))
            {
                length.addClass('text-success')
                length.removeClass('text-danger')
            }
            else
            {
                length.removeClass('text-success')
                length.addClass('text-danger')
            }

            if (validateLetters(passwordHolder.val()))
            {
                letters.addClass('text-success')
                letters.removeClass('text-danger')
            }
            else
            {
                letters.removeClass('text-success')
                letters.addClass('text-danger')
            }

            if (validateDigits(passwordHolder.val()))
            {
                digits.addClass('text-success')
                digits.removeClass('text-danger')
            }
            else
            {
                digits.removeClass('text-success')
                digits.addClass('text-danger')
            }

            if (validateSpecial(passwordHolder.val()))
            {
                special.addClass('text-success')
                special.removeClass('text-danger')
            }
            else
            {
                special.removeClass('text-success')
                special.addClass('text-danger')
            }

            if (passwordHolder.val() === passwordRepeatHolder.val())
            {
                same.addClass('text-success')
                same.removeClass('text-danger')
            }
            else
            {
                same.removeClass('text-success')
                same.addClass('text-danger')
            }

            if (validatePassword(passwordHolder.val()) && (passwordHolder.val() === passwordRepeatHolder.val()))
            {

                button.attr('disabled', false)

            }
            else
            {

                button.attr('disabled', true)

            }
        }
        catch (e)
        {

        }

    }

    $(() => {

        validateAll()

    })

    $(document).on('keyup', '.password' , () => {

        validateAll()

    })

    $(document).on('keyup', '.repeatPassword' , () => {

        validateAll()

    })

}