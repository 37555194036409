// global libs
const $ = require('jquery')
require('select2/dist/js/select2.full') // select2 js files
require('select2/dist/js/i18n/pl') // select2 Polish language pack
const routes = require('../../../public/js/fos_js_routes.json')
const Routing = require('../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.js')
const Swal = require('sweetalert2/dist/sweetalert2.all.min')

window.$ = window.jQuery = $

Routing.setRoutingData(routes)

window.Routing = Routing;

window.Swal = Swal

// tempalte
require('./template/tether.min')
require('./template/bootstrap.min')
require('./template/jquery-migrate.min')
require('./template/jquery-ui.min')
require('./template/hidemaxlistitem.min')
require('./template/jquery.easing.min')
require('./template/scrollup.min')
require('./template/jquery.waypoints.min')
require('./template/waypoints-sticky.min')
// require('./template/pace.min')
require('./template/slick.min')
require('./template/initSlick')
require('./template/scripts')

// custom
require('./custom/pages')
require('./custom/global')

// styles
import '../scss/index.scss'