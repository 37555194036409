import {loader} from "../parts/loader"
import {header} from "../parts/header"

export const serviceCard = () => {

    $(document).on('submit','#cart_form', function (e){
        e.preventDefault();

        const data = $(this).serialize();

        loader.show()

        $.ajax({
            type: 'post',
            url: Routing.generate('cart_add_service'),
            data: data,
        }).done( d => {
            loader.hide()
            if (d.ok)
            {
                Swal.fire({
                    icon: "success",
                    text: 'Poprawnie dodano usługę do koszyka',
                    confirmButtonColor: '#64b1bd',
                    confirmButtonText: 'Przejdź do koszyka',
                    showCancelButton: true,
                    cancelButtonColor: "#2c2d33",
                    cancelButtonText: "Kontynuuj zakupy",
                    allowOutsideClick: false
                }).then( d => {
                    if (d.isConfirmed)
                    {
                        location.href = Routing.generate('cart_preview')
                    }
                    else
                    {
                        loader.show()

                        header.refreshCart().then(() => {
                            header.refreshCartMobile().then(() => {
                                loader.hide()
                            })
                        })

                    }
                })
            }
            else if (d.error)
            {
                Swal.fire({
                    icon: "error",
                    title: 'Błąd',
                    text: d.error,
                    confirmButtonColor: '#64b1bd',
                    confirmButtonText: 'Ok',
                })
            }
        })

    });

}