import {loader} from "../parts/loader"

export const itemsList = (service) => {

    let url = ''

    function reloadList() {
        loader.show()
        $.ajax({
            type: 'GET',
            url: url,
        }).done( (d) => {
            $('#content').html(d)
            loader.hide()
        })
    }

    function updateUrlParams(key, value) {
        url = window.location.href;
        let queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get(key) !== null) {
            url = url.replace((key + '=' + urlParams.get(key)), key + '=' + value);
        } else {
            if (queryString === '') {
                url = url + '?' + key + '=' + value;
            } else {
                url = url + '&' + key + '=' + value;
            }
        }
        window.history.pushState(document.title, document.title, url);
    }

    function prepareUrl(slug, id) {
        url = Routing.generate( service === 0 ? 'product_list' : 'service_list', {
            'slug': slug,
            'id': id
        })
        const allowed = ['perPage', 'listType', 'priceMin', 'priceMax', 'orderBy'];
        const paramString = window.location.search;
        let newParamString = '?';
        let urlParams = new URLSearchParams(paramString);
        urlParams.forEach(function (value, key) {
            if (allowed.includes(key)) {
                newParamString += key + "=" + value + "&";
            }
        })
        newParamString = newParamString.slice(0, -1);
        url = url + newParamString;
    }

    function processRegex(obj, replaceVal)
    {
        let v = obj.val().replace(",", ".")
        if (!(/^\d*$/.test(v)))
            if (/^\d+\.\d+$/.test(v))
                obj.val(parseFloat(v).toFixed(2).replace(".", ","))
            else
                obj.val(replaceVal)
        return parseFloat(obj.val().replace(",", "."))
    }

    $(document)
        .on('change', '.categories-input', function () {
            updateUrlParams('page', '1')
            updateUrlParams($(this).attr('name'),$(this).val());
            reloadList()
        })

        .on('click', '.next-page', function () {
            let gotoPage = $('#goto-page');
            if (parseInt(gotoPage.val()) < parseInt($('.all-pages').html()))
                gotoPage.val(parseInt(gotoPage.val())+1).trigger('change');
        })
        .on('click', '.prev-page', function () {
            let gotoPage = $('#goto-page');
            if (parseInt(gotoPage.val()) > 1)
                gotoPage.val(parseInt(gotoPage.val())-1).trigger('change');
        })
        .on('click', '#filter-prices-button', function () {
            updateUrlParams('page', '1')
            updateUrlParams('priceMin', $('#priceMin').val().replace(",", "."));
            updateUrlParams('priceMax', $('#priceMax').val().replace(",", "."));
            reloadList()
        })
        .on('change', '#priceMin', function () {
            const priceMin = $(this).data('min').toString().replace(".", ",")
            let v = processRegex($(this), priceMin)
            const priceMax = parseFloat($('#priceMax').val().replace(",", "."))

            if (v > priceMax) {
                $(this).val(priceMax.toString().replace(".", ","));
            } else if (v < parseFloat($(this).data('min')) || !$(this).val()) {
                $(this).val(priceMin)
            }
        })
        .on('change', '#priceMax', function () {
            const priceMax = $(this).data('max').toString().replace(".", ",")
            let v = processRegex($(this), priceMax)
            const priceMin = parseFloat($('#priceMin').val().replace(",", "."))

            if (v < priceMin) {
                $(this).val(priceMin.toString().replace(".", ","));
            } else if (v > parseFloat($(this).data('max')) || !$(this).val()) {
                $(this).val(priceMax)
            }
        })
        .on('click', '.color-select', function () {
            updateUrlParams('page', '1')
            updateUrlParams('color', $(this).data('value'));
            reloadList()
        })
        .on('change', 'input[type=radio][name=filter-size]', function () {
            updateUrlParams('page', '1')
            updateUrlParams('size', $(this).val());
            reloadList()
        })
        .on('click', '.category-link', function () {
            const slug = $(this).data('slug');
            const id = $(this).data('id');
            prepareUrl(slug, id);
            window.history.pushState(document.title, document.title, url);
            reloadList()
        })
        .on('click', '.list-view', function (e) {
            if (!($(e.target).is('.quickCart')) && !($(e.target).is('.add_to_wishlist'))) {
                window.location.assign($(this).data('href'))
            }
        })
        .on('click', 'a.product-grid-item', function (e) {
            if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                if (!($(e.target).is('.add_to_wishlist'))) {
                    e.preventDefault()
                    window.location.assign($(this).data('href'))
                }
            }
        })

    jQuery( function( $ ){
        const currentPageNo = location.hash || 1
        const popStateRefresh = function(){
            const pageNo = location.hash
            if( pageNo !== currentPageNo ){
                loader.show()
                $.ajax({
                    type: 'GET',
                    url: this.location.href
                }).done( (d) => {
                    $('#content').html(d)
                    loader.hide()
                })
            }
        }
        $( window ).bind( 'popstate', popStateRefresh )
    })

    window.addEventListener( "pageshow", function ( event ) {
        let historyTraversal = event.persisted ||
            ( typeof window.performance != "undefined" &&
                window.performance.navigation.type === 2 );
        if ( historyTraversal ) {
            loader.show()
            $.ajax({
                type: 'GET',
                url: this.location.href
            }).done( (d) => {
                $('#content').html(d)
                loader.hide()
            })
        }
    });

}