export const header = {

    _headerHolder: document.getElementById('masthead'),
    _cartHolder: document.getElementById('site-header-cart'),
    _mobileCartHolder: document.getElementById('mobile-cart-holder'),

    refresh()
    {

        return new Promise(resolve => {

            $.ajax({
                type: 'GET',
                url: Routing.generate('header')
            }).done( d => {

                if (d.html)
                {
                    this._headerHolder.innerHTML = d.html
                    resolve()
                }

            }).fail( () => {
                resolve('Failed to load header')
            })

        })

    },

    refreshCart()
    {

        return new Promise(resolve => {

            $.ajax({
                type: 'GET',
                url: Routing.generate('header_cart')
            }).done( d => {

                if (d.html)
                {
                    this._cartHolder.innerHTML = d.html
                    resolve()
                }

            }).fail( () => {
                resolve('Failed to load header')
            })

        })

    },

    refreshCartMobile()
    {

        return new Promise(resolve => {

            $.ajax({
                type: 'GET',
                url: Routing.generate('header_cart_mobile')
            }).done( (d) => {

                if (d.html)
                {

                    // this._mobileCartHolder.innerHTML = d.html
                    resolve()

                }

            }).fail( () => {
                resolve('Failed to load header')
            })

        })

    }

}