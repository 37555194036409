import {loader} from "../parts/loader"
import {header} from "../parts/header"
import {initSelect2} from "../../../../panel/js/custom/functions/initSelect2";

export const cartPreview = () => {

    let updateTimer = null
    let updateXHR = null

    $(document).on('change', '.qty', function (){

        const type = $(this).data('type')

        let object, url

        if (type === 0)
        {
            object = $(this).data('product')
            url = Routing.generate('cart_change_qty', {id: object})
        }
        else
        {
            object = $(this).data('service')
            url =  Routing.generate('cart_change_s_qty', {id: object})
        }


        const holder = $('#main')

        if (updateTimer !== null)
        {
            clearTimeout(updateTimer)
            loader.hide()
        }


        if (updateXHR && updateXHR.readyState !== 4)
        {
            updateXHR.abort()
            loader.hide()
        }

        updateTimer = setTimeout( () => {

            loader.show()

            updateXHR = $.ajax({
                url: url,
                data: {
                    qty: $(this).val()
                }
            }).done( (d) => {
                if (d.ok)
                {
                    $.ajax({
                        type: 'get',
                        url: Routing.generate('cart_reload_preview')
                    }).done( d => {

                        if (d.html)
                        {
                            holder.html(d.html)

                            header.refreshCart().then(() => {
                                header.refreshCartMobile().then(() => {
                                    loader.hide()
                                })
                            })
                        }

                    })
                }
                else if(d.error)
                {
                    loader.hide()

                    Swal.fire({
                        icon: 'error',
                        text: d.error,
                        confirmButtonColor: '#de0613'
                    })

                }
            })
        }, 500)

    })

    $(document).on('click', '.remove', function (){

        const button = $(this);
        const id = button.data('id')
        const type = button.data('type')
        const holder = $('#main')

        const routename = type === 0 ? 'cart_remove_product' : 'cart_remove_service'

        let url = Routing.generate(routename, {id: id})

        loader.show()

        $.ajax({
            type: 'get',
            url: url
        }).done( d => {

            if (d.ok)
            {
                url = Routing.generate('cart_reload_preview')

                $.ajax({
                    type: 'get',
                    url: url
                }).done( d => {

                    if (d.html)
                    {
                        holder.html(d.html)

                        header.refreshCart().then(() => {
                            header.refreshCartMobile().then(() => {
                                loader.hide()
                            })
                        })
                    }

                })

            }
            else if(d.error)
            {
                loader.hide()

                Swal.fire({
                    icon: 'error',
                    text: d.error
                })

            }

        })

    })

    $(document).on('click', '.removeSub', function (){

        const button = $(this);
        const id = button.data('id')
        const holder = $('#main')

        let url = Routing.generate('cart_remove_subscription', {id: id})

        loader.show()

        $.ajax({
            type: 'get',
            url: url
        }).done( d => {

            if (d.ok)
            {
                url = Routing.generate('cart_reload_preview')

                $.ajax({
                    type: 'get',
                    url: url
                }).done( d => {

                    if (d.html)
                    {
                        holder.html(d.html)

                        header.refreshCart().then(() => {
                            header.refreshCartMobile().then(() => {
                                loader.hide()
                            })
                        })
                    }

                })

            }
            else if(d.error)
            {
                loader.hide()

                Swal.fire({
                    icon: 'error',
                    text: d.error
                })

            }

        })

    })

    $(document).on('click', '.cleanCart', function (){

        loader.show()
        const holder = $('#main')

        $.ajax({
            type: 'get',
            url: Routing.generate('cart_clean_cart')
        }).done( d => {

            if (d.ok)
            {

                $.ajax({
                    type: 'get',
                    url: Routing.generate('cart_reload_preview')
                }).done( d => {

                    if (d.html)
                    {
                        holder.html(d.html)

                        header.refreshCart().then(() => {
                            header.refreshCartMobile().then(() => {
                                loader.hide()
                            })
                        })
                    }

                })

            }
            else
            {
                loader.hide()

                Swal.fire({
                    icon: 'error',
                    text: 'Wystąpił błąd',
                    confirmButtonColor: '#de0613'
                })

            }

        })

    })

    $(document).on('change', '.change-selection', function (){

        const type = $(this).data('type')

        let selection = 0
        let url
        if($(this).is(":checked")){
            selection = 1
        }

        if (type === 0)
        {
            url = Routing.generate('cart_change_selection', {id: $(this).data('product'), status: selection})
        }
        else
        {
            url = Routing.generate('cart_change_s_selection', {id: $(this).data('service'), status: selection})
        }

        const holder = $('#main')

        if (updateTimer !== null)
        {
            clearTimeout(updateTimer)
            loader.hide()
        }


        if (updateXHR && updateXHR.readyState !== 4)
        {
            updateXHR.abort()
            loader.hide()
        }

        updateTimer = setTimeout( () => {

            loader.show()

            updateXHR = $.ajax({
                url: url,
                data: {
                    qty: $(this).val()
                }
            }).done( (d) => {
                if (d.ok)
                {
                    $.ajax({
                        type: 'get',
                        url: Routing.generate('cart_reload_preview')
                    }).done( d => {

                        if (d.html)
                        {
                            holder.html(d.html)

                            header.refreshCart().then(() => {
                                header.refreshCartMobile().then(() => {
                                    loader.hide()
                                })
                            })
                        }

                    })
                }
                else if(d.error)
                {
                    loader.hide()

                    Swal.fire({
                        icon: 'error',
                        text: d.error,
                        confirmButtonColor: '#de0613'
                    })

                }
            })
        }, 500)

    })

    $(document).on('change', '.check-all', function () {

        const type = $(this).data('type')

        let selection = 0

        if($(this).is(":checked")){
            selection = 1
        }

        const holder = $('#main')

        if (updateXHR && updateXHR.readyState !== 4)
        {
            updateXHR.abort()
            loader.hide()
        }

        updateTimer = setTimeout( () => {

            loader.show()

            updateXHR = $.ajax({
                url: Routing.generate('cart_change_all_selection', {type: type, status: selection}),
                data: {
                    qty: $(this).val()
                }
            }).done( (d) => {
                if (d.ok)
                {
                    $.ajax({
                        type: 'get',
                        url: Routing.generate('cart_reload_preview')
                    }).done( d => {

                        if (d.html)
                        {
                            holder.html(d.html)

                            header.refreshCart().then(() => {
                                header.refreshCartMobile().then(() => {
                                    loader.hide()
                                })
                            })
                        }

                    })
                }
                else if(d.error)
                {
                    loader.hide()

                    Swal.fire({
                        icon: 'error',
                        text: d.error,
                        confirmButtonColor: '#de0613'
                    })

                }
            })
        }, 500)

    })

    $(document).on('click', '.become-member', function (){


        loader.show()

        fetch(Routing.generate('subscriptions_available_packages'))
            .then( r => r.json())
            .then(data => {
                loader.hide()

                const packages = {}

                data.forEach((elem) => {
                    packages[elem.id] = elem.name
                })

                Swal.fire({
                    icon: 'question',
                    title: 'Wybierz wariant subskrypcji',
                    allowOutsideClick: false,
                    input: 'select',
                    inputOptions: packages,
                    inputPlaceholder: 'Wariant subskrypcji',
                    showCancelButton: true,
                    inputValidator: value => {
                        return new Promise( resolve => {
                            if (value !== '')
                                resolve()
                            else
                                resolve('Musisz wybrać wariant!')
                        })
                    },
                    cancelButtonText: 'Anuluj',
                    confirmButtonText: 'Wybierz',
                    cancelButtonColor: '#6c757d',
                    confirmButtonColor: '#3f51b5',
                    reverseButtons: true,
                    didOpen: () => {
                        initSelect2('select', {
                            minimumResultsForSearch: Infinity
                        })

                        $('.select2-container').addClass('ml-auto').addClass('mr-auto').addClass('mt-2')
                    }
                }).then( r => {

                    const packageId = r.value

                    if (r.isConfirmed)
                    {
                        loader.show()

                        fetch(Routing.generate('subscriptions_available_periods'))
                            .then(r => r.json())
                            .then(data => {

                                const periods = {}

                                data.forEach(elem => {
                                    periods[elem.id] = elem.name
                                })

                                loader.hide()

                                Swal.fire({
                                    icon: 'question',
                                    title: `Wybrano pakiet ${packages[packageId]}. <br>Wybierz okres dla pakietu.`,
                                    allowOutsideClick: false,
                                    input: 'select',
                                    inputOptions: periods,
                                    inputPlaceholder: 'Okres subskrypcji',
                                    showCancelButton: true,
                                    inputValidator: value => {
                                        return new Promise( resolve => {
                                            if (value !== '')
                                                resolve()
                                            else
                                                resolve('Musisz wybrać okres!')
                                        })
                                    },
                                    cancelButtonText: 'Anuluj',
                                    confirmButtonText: 'Wybierz',
                                    cancelButtonColor: '#6c757d',
                                    confirmButtonColor: '#3f51b5',
                                    reverseButtons: true,
                                    didOpen: () => {
                                        initSelect2('select', {
                                            minimumResultsForSearch: Infinity
                                        })

                                        $('.select2-container').addClass('ml-auto').addClass('mr-auto').addClass('mt-2')
                                    }
                                }).then( r => {

                                    const periodId = r.value

                                    if (r.isConfirmed)
                                    {
                                        Swal.fire({
                                            icon: 'question',
                                            title: 'Czy na pewno chcesz dodać subskrypcję?',
                                            html: `Wybrany pakiet: <b>${packages[packageId]}</b><br>Wybrany okres: <b>${periods[periodId]}</b>`,
                                            allowOutsideClick: false,
                                            showCancelButton: true,
                                            cancelButtonText: 'Anuluj',
                                            confirmButtonText: 'Potwierdź',
                                            cancelButtonColor: '#6c757d',
                                            confirmButtonColor: '#3f51b5',
                                            reverseButtons: true,
                                        }).then( r => {

                                            if (r.isConfirmed)
                                            {
                                                loader.show()

                                                $.ajax({
                                                    type: 'post',
                                                    url: Routing.generate('cart_add_subscription'),
                                                    data: {
                                                        package_id: packageId,
                                                        period_id: periodId
                                                    }
                                                }).done( d => {

                                                    loader.hide()

                                                    if (d.error)
                                                    {
                                                        Swal.fire({
                                                            icon: 'error',
                                                            title: 'Wystąpił błąd dodawania subskrypcji',
                                                            text: d.error,
                                                            confirmButtonText: 'OK',
                                                            confirmButtonColor: '#3f51b5'
                                                        })
                                                    }
                                                    else
                                                    {
                                                        Swal.fire({
                                                            icon: 'success',
                                                            title: 'Pomyślnie dodano subskrypcję',
                                                            confirmButtonText: 'OK',
                                                            confirmButtonColor: '#3f51b5',
                                                            allowOutsideClick: false
                                                        }).then( () => location.reload() )
                                                    }

                                                })
                                            }
                                            else
                                            {
                                                Swal.fire({
                                                    icon: 'warning',
                                                    title: 'Anulowano dodawanie subskrypcji',
                                                    confirmButtonText: 'OK',
                                                    confirmButtonColor: '#3f51b5'
                                                })
                                            }

                                        })

                                    }
                                    else
                                    {
                                        Swal.fire({
                                            icon: 'warning',
                                            title: 'Anulowano dodawanie subskrypcji',
                                            confirmButtonText: 'OK',
                                            confirmButtonColor: '#3f51b5'
                                        })
                                    }
                                })
                            })
                    }
                    else
                    {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Anulowano dodawanie subskrypcji',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#3f51b5'
                        })
                    }
                })
            })
    })

    $(document).on('click', '.apply_coupon', function (e){

        e.preventDefault()
        const cart = $(this).data('cart')
        const coupon = $('#coupon_code').val()

        if (coupon === '') return

        const holder = $('#main')
        loader.show()

        $.ajax({
            url: Routing.generate('cart_add_discount_code', {cartId: cart, code: coupon})
        }).done( function (d) {
            if (d.ok)
            {
                $.ajax({
                    type: 'get',
                    url: Routing.generate('cart_reload_preview')
                }).done( d => {
                    if (d.html)
                    {
                        holder.html(d.html)
                        loader.hide()
                    }
                })
            }
            else
            {
                loader.hide()
                Swal.fire({
                    icon: 'error',
                    title: d.message,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#3f51b5'
                })
            }

        })

    })

    $(document).on('click', '.remove-coupon', function () {

        const cart = $(this).data('cart');
        const coupon = $(this).data('coupon');

        const holder = $('#main')
        loader.show()

        $.ajax({
            url: Routing.generate('cart_remove_discount_code', {couponId: coupon, cartId: cart})
        }).done( function (d) {
            if (d.ok)
            {
                $.ajax({
                    type: 'get',
                    url: Routing.generate('cart_reload_preview')
                }).done( d => {
                    if (d.html)
                    {
                        holder.html(d.html)
                        loader.hide()
                    }
                })
            }
            else
            {
                loader.hide()
            }
        })

    })


}