export const searchWidget = {

    getSimilarQueries(query, category = null)
    {

        const url = Routing.generate('quick_search')
        const loadingContent = `<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`
        const loadingContentShort = `<p class="loading">Wpisz minimum 2 znaki</p>`
        const box = $('#similar-queries-box')

        box.slideDown('slow')
        box.css('display', 'flex')
        if (query.length >= 2)
            box.html(loadingContent)
        else
        {
            box.html(loadingContentShort)
            return
        }

        let data

        if (category)
        {
            data = {
                q: query,
                c: category
            }
        }
        else
        {
            data = {
                q: query
            }
        }

        $.ajax({
            type: 'get',
            url: url,
            data: data
        }).done((d) => {
            box.html(d.html)
        })

    },

    getSimilarQueriesMobile(query)
    {

        const url = Routing.generate('quick_search')
        const loadingContent = `<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>`
        const loadingContentShort = `<p class="loading">Wpisz minimum 3 znaki</p>`
        const box = $('#mobile-quick-search')

        box.slideDown('slow')
        box.css('display', 'flex')
        if (query.length >= 3)
            box.html(loadingContent)
        else
        {
            box.html(loadingContentShort)
            return
        }

        $.ajax({
            type: 'get',
            url: url,
            data: {
                q: query
            }
        }).done((d) => {
            box.html(d.html)
        })

    }

}