$(document).on('click', '.share', function (e) {
    e.preventDefault()

    const popup = document.querySelector(".popup"),
        input = $('.share-input'),
        productId = $(this).data('product'),
        sizeId = $(this).data('size'),
        slug = $(this).data('slug'),
        holder = document.querySelector(".popup-holder"),
        serviceId = $(this).data('service')

    let url = null
    const host = 'https://' + location.host

    if (productId)
    {
        if (sizeId !== undefined)
        {
            url = Routing.generate('product_card', {slug: slug, id: productId, size: sizeId})
        }
        else
        {
            url = Routing.generate('product_card', {slug: slug, id: productId})
        }
    }
    else if (serviceId)
    {
        url = Routing.generate('service_card', {slug: slug, id: serviceId})
    }


    input.val(host + url)

    holder.classList.toggle("show");
    popup.classList.toggle("show");

})

$(document).on('click', ".popup-close", function () {

    const popup = document.querySelector(".popup"),
        holder = document.querySelector(".popup-holder")

    popup.classList.toggle("show");
    holder.classList.toggle("show");
})

$(document).on('click', '.share-copy', function () {
    const input = document.querySelector('.share-input'),
        field = document.querySelector('.share-field'),
        copy = this

    input.select();

    if(document.execCommand("copy")){ //if the selected text copy
        field.classList.add("active");
        copy.innerText = "Skopiowano";
        setTimeout(()=>{
            window.getSelection().removeAllRanges(); //remove selection from document
            field.classList.remove("active");
            copy.innerText = "Kopiuj";
        }, 3000);
    }

})