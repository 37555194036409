import {loader} from "../parts/loader"

export const account = () => {

    $(document).on('click', '.remove-address', function (e) {
        e.preventDefault()
        const hash = $(this).data('hash')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć adres?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545',
        }).then( result => {
            if (result.isConfirmed) {

                loader.show()

                $.ajax({
                    type: 'post',
                    url: Routing.generate('account_delivery_remove', {hash: hash}),
                }).done(d => {

                    loader.hide()

                    if (!d.error) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Adres został usunięty',
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then(() => {
                            location.reload()
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć adresu',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })

                    }
                }).fail(e => {
                    loader.hide()

                    Swal.fire({
                        icon: 'error',
                        title: 'Nie udało się usunąć adresu',
                        text: e.error,
                        confirmButtonText: 'Zamknij',
                        confirmButtonColor: '#3f51b5',
                    })
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie adresu',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5'
                })
            }
        })
    })

    $(document).on('click', '.remove-invoice', function (e) {
        e.preventDefault()
        const hash = $(this).data('hash')

        Swal.fire({
            icon: 'question',
            title: 'Czy na pewno chcesz usunąć dane do faktury?',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showCancelButton: true,
            cancelButtonText: 'Nie, anuluj',
            confirmButtonText: 'Tak, usuń',
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#dc3545',
        }).then( result => {
            if (result.isConfirmed) {

                loader.show()

                $.ajax({
                    type: 'post',
                    url: Routing.generate('account_invoice_remove', {hash: hash}),
                }).done(d => {

                    loader.hide()

                    if (!d.error) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Dane do faktury zostały usunięte',
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then(() => {
                            location.reload()
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Nie udało się usunąć danych do faktury',
                            text: d.error,
                            confirmButtonText: 'Zamknij',
                            confirmButtonColor: '#3f51b5',
                        })

                    }
                }).fail(e => {
                    loader.hide()

                    Swal.fire({
                        icon: 'error',
                        title: 'Nie udało się usunąć danych do faktury',
                        text: e.error,
                        confirmButtonText: 'Zamknij',
                        confirmButtonColor: '#3f51b5',
                    })
                })
            }
            else
            {
                Swal.fire({
                    icon: 'warning',
                    title: 'Anulowano usuwanie danych do faktury',
                    confirmButtonText: 'Zamknij',
                    confirmButtonColor: '#3f51b5'
                })
            }
        })
    })

    function zipMasks()
    {

        const inputs = $('[data-mask=postalCode]')

        inputs.each( function () {

            $(this).inputmask("99-999")

        })

    }

    $(document).on('submit', '#addressForm', function (e) {
        e.preventDefault();

        const parent = $(this).parents('.modal-body')
        const mode = parent.data('mode')
        const hash = parent.data('hash')
        const url = Routing.generate('account_address_add', {type: mode, hash: hash})
        const modal = parent.parents('.modal')

        parent.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'post',
            url: url,
            data: $(this).serialize()
        }).done(d => {
            if (d.html)
            {
                modal.modal('hide')
                location.reload()
            }
            else if (d.form)
            {
                parent.html(d.form)
                zipMasks()
            }
        })

    })

    $('#modalDelivery').on('show.bs.modal', function (event) {
        const modal = $(this)
        const body = modal.find('.modal-body')
        const hash = $(event.relatedTarget).data('hash') !== undefined ? $(event.relatedTarget).data('hash') : '0';
        const url = Routing.generate('account_address_add', {type: 0, hash: hash})

        body.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'get',
            url: url
        }).done((d) => {
            if (d.form)
            {
                body.html(d.form)
                body.attr('data-mode', 0)
                body.attr('data-hash', hash)
                zipMasks()
            }
        })
    })

    $('#modalInvoice').on('show.bs.modal', function (event) {
        const modal = $(this)
        const body = modal.find('.modal-body')
        const hash = $(event.relatedTarget).data('hash') !== undefined ? $(event.relatedTarget).data('hash') : '0';
        const url = Routing.generate('account_address_add', {type: 1, hash: hash})

        body.html(`<div class="d-flex w-100 flex-column justify-content-center align-items-center"><div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div><p class="loading">Trwa ładowanie...</p></div>`)

        $.ajax({
            type: 'get',
            url: url
        }).done((d) => {
            if (d.form)
            {
                body.html(d.form)
                body.attr('data-mode', 1)
                body.attr('data-hash', hash)
                zipMasks()
            }
        })

    })

    $( () => {

        $('[data-toggle="tooltip"]').tooltip()
        zipMasks()

    })
}