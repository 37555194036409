import {loader} from "../parts/loader"
import {header} from "../parts/header"
import {initSmoothScroll} from "../functions/smoothScroll"

export const productCard = (productId, productSlug) => {

    function initCarousel()
    {
        $('#productPhotos').carousel()
    }

    $(document).on('change', '#size_id', function() {

        const sizeId = $(this).val();

        loader.show()

        let currentUrl = location.href
        let tempArray = currentUrl.split('?')

        let newUrl = tempArray[0] + '?size=' + sizeId

        $.ajax({
            type: 'get',
            data: {
                size: sizeId
            },
            url: Routing.generate('product_card', {'slug': productSlug, 'id': productId})
        }).done( d => {
            $( '#content' ).html(d);
            initCarousel()
            window.initSlick()
            history.pushState({}, null, newUrl)
            initSmoothScroll()
            loader.hide()
        })

    })

    $(document).on('change', '#quantity-input', function (){

        const input = $(this)
        const min = 1
        const max = input.data('stock')

        if (input.val() < min)
            input.val(min)

        if (input.val() > max)
            input.val(max)

    })

    $(document).on('click', '.button-plus', function () {

        const quantityInput = $('#quantity-input')
        quantityInput.val(Number(quantityInput.val()) + 1)
        quantityInput.change()

    })

    $(document).on('click', '.button-minus', function () {

        const quantityInput = $('#quantity-input')
        quantityInput.val(Number(quantityInput.val()) - 1)
        quantityInput.change()

    })

    $(document).on('submit','#cart_form', function (e){
        e.preventDefault();

        const data = $(this).serialize();

        loader.show()

        $.ajax({
            type: 'post',
            url: Routing.generate('cart_add_product'),
            data: data,
        }).done( d => {
            loader.hide()
            if (d.ok)
            {
                Swal.fire({
                    icon: "success",
                    text: 'Poprawnie dodano produkt do koszyka',
                    confirmButtonColor: '#64b1bd',
                    confirmButtonText: 'Przejdź do koszyka',
                    showCancelButton: true,
                    cancelButtonColor: "#2c2d33",
                    cancelButtonText: "Kontynuuj zakupy",
                    allowOutsideClick: false
                }).then( d => {
                    if (d.isConfirmed)
                    {
                        location.href = Routing.generate('cart_preview')
                    }
                    else
                    {
                        loader.show()

                        header.refreshCart().then(() => {
                            header.refreshCartMobile().then(() => {
                                loader.hide()
                            })
                        })

                        initSmoothScroll()

                    }
                })
            }
            else if (d.error)
            {
                Swal.fire({
                    icon: "error",
                    title: 'Błąd',
                    text: d.error,
                    confirmButtonColor: '#64b1bd',
                    confirmButtonText: 'Ok',
                })
            }
        })

    });

    $(() => {
        initCarousel()
    })

}