export const checkoutLogin = () => {

    const checkbox = $('[data-invoice]')
    let state = false;

    function invoiceRequired()
    {

        const inputs = $('[data-required=invoice]')

        inputs.each(function () {
            $(this).attr('required', checkbox.is(':checked'))
        })

        if (checkbox.is(':checked') && state === false)
        {
            $('#collapseFV').css("display", "flex").hide().slideDown()
            state = true
        }
        else if(!checkbox.is(':checked'))
        {
            $('#collapseFV').slideUp()
            state = false
        }

    }

    function zipMasks()
    {

        const inputs = $('[data-mask=postalCode]')

        inputs.each( function () {

            $(this).inputmask("99-999")

        })

    }

    $(document).on('change', checkbox, () => {
        invoiceRequired()
    })

    $(() => {
        invoiceRequired()
        zipMasks()
    })

}