import {loader} from "../parts/loader"
import {initSelect2} from "../../../../panel/js/custom/functions/initSelect2";
import {header} from "../parts/header";
import {initSmoothScroll} from "../functions/smoothScroll";

export const priceList = () => {

    $(document).on('click', '.addSub', function () {
        const packageId = $(this).data('package')
        const name = $(this).data('name')

        loader.show()

        fetch(Routing.generate('subscriptions_available_periods'))
            .then(r => r.json())
            .then(data => {

                const periods = {}

                data.forEach(elem => {
                    periods[elem.id] = elem.name
                })

                loader.hide()

                Swal.fire({
                    icon: 'question',
                    title: `Wybierz okres dla pakietu ${name}`,
                    allowOutsideClick: false,
                    input: 'select',
                    inputOptions: periods,
                    inputPlaceholder: 'Okres subskrypcji',
                    showCancelButton: true,
                    inputValidator: value => {
                        return new Promise( resolve => {
                            if (value !== '')
                                resolve()
                            else
                                resolve('Musisz wybrać okres!')
                        })
                    },
                    cancelButtonText: 'Anuluj',
                    confirmButtonText: 'Wybierz',
                    cancelButtonColor: '#6c757d',
                    confirmButtonColor: '#3f51b5',
                    reverseButtons: true,
                    didOpen: () => {
                        initSelect2('select', {
                            minimumResultsForSearch: Infinity
                        })

                        $('.select2-container').addClass('ml-auto').addClass('mr-auto').addClass('mt-2')
                    }
                }).then( r => {

                    const periodId = r.value

                    if (r.isConfirmed)
                    {
                        Swal.fire({
                            icon: 'question',
                            title: 'Czy na pewno chcesz zakupić subskrypcję?',
                            html: `Wybrany pakiet: <b>${name}</b><br>Wybrany okres: <b>${periods[periodId]}</b>`,
                            allowOutsideClick: false,
                            showCancelButton: true,
                            cancelButtonText: 'Anuluj',
                            confirmButtonText: 'Potwierdź',
                            cancelButtonColor: '#6c757d',
                            confirmButtonColor: '#3f51b5',
                            reverseButtons: true,
                        }).then( r => {

                            if (r.isConfirmed)
                            {
                                loader.show()

                                $.ajax({
                                    type: 'post',
                                    url: Routing.generate('cart_add_subscription'),
                                    data: {
                                        package_id: packageId,
                                        period_id: periodId
                                    }
                                }).done( d => {

                                    loader.hide()


                                    if (d.error)
                                    {
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Wystąpił błąd dodawania subskrypcji',
                                            text: d.error,
                                            confirmButtonText: 'OK',
                                            confirmButtonColor: '#3f51b5'
                                        })
                                    }
                                    else
                                    {
                                        Swal.fire({
                                            icon: "success",
                                            text: 'Poprawnie dodano subskrypcję do koszyka',
                                            confirmButtonColor: '#64b1bd',
                                            confirmButtonText: 'Przejdź do koszyka',
                                            showCancelButton: true,
                                            cancelButtonColor: "#2c2d33",
                                            cancelButtonText: "Kontynuuj zakupy",
                                            allowOutsideClick: false
                                        }).then( d => {
                                            if (d.isConfirmed)
                                            {
                                                location.href = Routing.generate('cart_preview')
                                            }
                                            else
                                            {
                                                loader.show()

                                                header.refreshCart().then(() => {
                                                    header.refreshCartMobile().then(() => {
                                                        loader.hide()
                                                    })
                                                })

                                                initSmoothScroll()

                                            }
                                        })
                                    }

                                })
                            }
                            else
                            {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Anulowano zakup subskrypcji',
                                    confirmButtonText: 'OK',
                                    confirmButtonColor: '#3f51b5'
                                })
                            }

                        })

                    }
                    else
                    {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Anulowano wybór subskrypcji',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#3f51b5'
                        })
                    }
                })
            })
    })

}