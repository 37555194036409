import {loader} from "../parts/loader";
import {initSelect2} from "../../../../panel/js/custom/functions/initSelect2"

export const board = type => {

    $('#addModal').on('show.bs.modal', function (event) {

        const modal = $(this)

        loader.show()

        fetch(Routing.generate('post_proposal', {type: type}))
            .then( r => r.json())
            .then( data => {
                modal.find('.modal-body').html(data.html)
                initSelect2('.select2', {
                    language: 'pl',
                    minimumResultsForSearch: Infinity
                })
                loader.hide()
            })

    })

    $(document).on('submit', '#proposalForm', function (e) {

        e.preventDefault();
        const form = $(this)

        loader.show()

        $.ajax({
            type: 'post',
            url: Routing.generate('post_proposal', {type: type}),
            data: form.serialize()
        }).done(d => {

            if (d.ok)
            {
                $('#addModal').modal('hide')

                loader.hide()
                Swal.fire({
                    icon: 'success',
                    title: 'Sukces!',
                    text: 'Pomyślnie wysłano zgłoszenie wpisu!'
                })
            }
            else if (d.html)
            {
                $('#addModal').find('.modal-body').html(d.html)
                loader.hide()
            }

        }).catch( e => {
            loader.hide()
            Swal.fire({
                icon: 'error',
                title: 'Błąd!',
                text: 'Wystąpił błąd serwera. Spróbuj ponownie później.'
            })
        })

    })

}