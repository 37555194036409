export function initSmoothScroll() {

    $('a[href*="#"]')
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            if (
                location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
                &&
                location.hostname === this.hostname
            ) {
                let target = $(this.hash);
                if($(event.currentTarget).data('toggle'))
                {
                    return
                }
                else
                {
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top - ($('.techmarket-sticky-wrap').height() * 2)
                        }, 1000, function() {
                            const $target = $(target);
                            $target.focus();
                            if ($target.is(":focus")) {
                                return false;
                            } else {
                                $target.attr('tabindex','-1');
                                $target.focus();
                            }
                        });
                    }
                }
            }
        });

}