import {loginRegister} from "./loginRegister"
import {post} from "./post"
import {board} from "./board"
import {productCard} from "./productCard"
import {serviceCard} from "./serviceCard"
import {cartPreview} from "./cartPreview"
import {checkoutLogin} from "./checkoutLogin"
import {checkout} from "./checkout"
import {account} from "./account"
import {passwordValidation} from "./passwordValidation"
import {priceList} from "./priceList"
import {itemsList} from "./itemsList"

window.initLoginRegister = () => {
    loginRegister()
}

window.initPost = id => {
    post(id)
}

window.initBoard = type => {
    board(type)
}

window.initProductCard = (productId, productSlug) => {
    productCard(productId, productSlug)
}

window.initServiceCard = () => {
    serviceCard()
}

window.initCartPreview = () => {
    cartPreview()
}

window.initCheckoutLogin = () => {
    checkoutLogin()
}

window.initCheckout = () => {
    checkout()
}

window.initItemsList = service => {
    itemsList(service)
}

window.initAccount = () => {
    account()
}

window.initPasswordValidation = () => {
    passwordValidation()
}

window.initPriceList = () => {
    priceList()
}